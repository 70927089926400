body {
    font-family: "Roboto", helvetica, sans-serif;
    font-weight: 400;
    /*font-size: 16px;*/
    line-height: 1.7;
    color: $color-grey-dark;
}

.heading-primary {
    color: $color-primary;
    text-transform: uppercase;
    font-family: "Acme", sans-serif;
    backface-visibility: hidden;
    margin-bottom: 6rem;

    &--main {
        display: block;
        font-size: 6rem;
        font-weight: 400;
        letter-spacing: 3rem;
        margin-bottom: 4rem;
    
        animation-name: moveInLeft;
        animation-duration: 1s;
        animation-timing-function: ease-out;
        
        @include respond(tab-port) {
            letter-spacing: 1rem;
            font-size: 5rem;
        }

        @include respond(phone) {
            letter-spacing: 1rem;
            font-size: 4rem;
        }
        /*
        animation-delay: 3s;
        animation-iteration-count: 3;
        */
    }
    
    &--sub {
        display: block;
        font-size: 3rem;
        font-weight: 700;
        letter-spacing: 1.75rem;
        animation: moveInRight 1s ease-out;

        @include respond(tab-port) {
            letter-spacing: .5rem;
        }
    }
}

.heading-secondary {
    font-family: "Acme", sans-serif;
    font-size: 4rem;
    text-transform: uppercase;
    font-weight: 700;
    display: inline-block;
    // background-image: linear-gradient(to right, $color-primary-light, $color-primary-dark);
    background-clip: text;
    -webkit-background-clip: text;
    // color: transparent;
    color: $color-primary-light;
    letter-spacing: .2rem;
    transition: all .2s;
    margin-top: 5rem;
    margin-left: 2rem;
    margin-right: 2rem;

    @include respond(tab-port) {
        font-size: 4rem;
    }

    @include respond(phone) {
        font-size: 4rem;
    }

    // &:hover {
    //     transform: skewY(2deg) skewX(15deg) scale(1.1);
    //     text-shadow: .5rem 1rem 2rem rgba($color-black, .2);
    // }
}

.heading-tertiary {
    font-size: 3rem;
    font-family: "Acme", sans-serif;
    font-weight: 700;
    text-transform: uppercase;
}

.paragraph {
    font-size: $default-font-size;

    &:not(:last-child) {
        margin-bottom: 3rem;
    }
}

.paragraph img {
    max-width: 60%;

    @include respond(tab-port) {
        max-width: 100%;
    }
}

.highlight {
    color: $color-primary-highlight
}

.model-form-field {
    border-top: 1px solid black;
    margin-top: 5rem;
}