.header {
    // height: 85vh;
    // background-image: linear-gradient(to right bottom, rgba($color-primary-light, 0.4), rgba($color-primary-dark, 0.6)),
    //     url(../assets/header.jpg);
    // background-size: cover;
    // background-position: top;
    
    position: relative;
    margin: 20rem;

    @include respond(tab-port) {
        margin: 20rem 6rem 20rem 6rem ;
    }

    // @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
    //     -webkit-clip-path: polygon(0 0, 100% 0, 100% 75vh, 0 100%);
    //     clip-path: polygon(0 0, 100% 0, 100% 75vh, 0 100%);
    //     height: 95vh;
    // }

    // @media only screen and (min-resolution: 192dpi) and (min-width: 37.5em),
    //     only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 37.5em),
    //     only screen and (min-width: 125em) {
    //     background-image: linear-gradient(
    //             to right bottom,
    //             rgba($color-primary-light, 0.4),
    //             rgba($color-primary-dark, 0.6)
    //         ),
    //         url(../assets/header.jpg);
    // }

    // @include respond(tab-port) {
    //     -webkit-clip-path: polygon(0 0, 100% 0, 100% 85vh, 0 100%);
    //     clip-path: polygon(0 0, 100% 0, 100% 85vh, 0 100%);
    // }

    // &__logo-box {
    //     position: absolute;
    //     top: 2rem;
    //     left: 3rem;
    // }

    // &__logo {
    //     height: 8rem;
    // }

    &__text-box {
        // position: absolute;
        // top: 40%;
        // left: 50%;
        // transform: translate(-50%, -50%);
        color: black;
        text-align: center;
    }
}
