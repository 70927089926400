// COLORS
$color-primary: #000033;
$color-primary-light: #FF6666;
$color-primary-dark: #C70039;
$color-primary-highlight: #CC6600;

$color-secondary-light: #6699FF;
$color-secondary-dark: #6699CC;

$color-tertiary-light: #9999FF;
$color-tertiary-dark: #9999CC;

$color-grey-light-1: #f7f7f7;
$color-grey-light-2: #eee;
$color-grey-light-3: rgb(221, 221, 221);

$color-grey-dark: #000033;
$color-grey-dark-2: #999;
$color-grey-dark-3: #000033;

$color-white: #fff;
$color-black: #000033;


// FONT
$default-font-size: 1.6rem;

// GRID
$grid-width: 114rem;
$gutter-vertical: 8rem;
$gutter-vertical-small: 6rem;
$gutter-horizontal: 6rem;