.composition {
    position: relative;

    &__photo {
        width: 47%;
        // box-shadow: 0 1.5rem 4rem rgba($color-black, .4);
        // border-radius: 2px;
        float: left;
        position: relative; // absolute
        z-index: 10;
        transition: all .2s;
        outline-offset: 0.5rem;
        margin: 0.5%;

        @include respond(tab-port) {
            float: left;
            position: relative;
            width: 96%;
            margin: 1%;
            // box-shadow: 0 1.5rem 3rem rgba($color-black, .2);
        }

        &--portrait {
            width: 47%;
        }

        &--p1 {
            left: 0rem;
            top: 0rem;

            @include respond(tab-port)  {
                top: 0rem;
                // transform: scale(1.2);
            }
        }

        &--p2 {
            right: 0rem;
            top: 0rem;

            @include respond(tab-port)  {
                top: 0rem;
                // transform: scale(1.3);
                // z-index: 100;
            }
        }

        &--p3 {
            left: 0rem;
            top: 0rem;

            @include respond(tab-port)  {
                left: 0rem;
                top: 0rem;
                // transform: scale(1.1);
            }
        }

        &--p4 {
            left: 0rem;
            top: 0rem;

            @include respond(tab-port)  {
                left: 0rem;
                top: 0rem;
                // transform: scale(1.1);
            }
        }

        &:hover {
            outline: 1rem solid $color-primary;
            transform: scale(1.05) translateY(-.5rem);
            box-shadow: 0 2.5rem 4rem rgba($color-black, .5);
            z-index: 20;
        }

    }

    &:hover &__photo:not(:hover) {
        transform: scale(.95);
    }
    //composition:hover composition__photo:not(:hover) {
}

.row__composition__1 {
    height: 1rem;
    padding: 0 !important;
}

.row__composition__2 {
    height: 1rem;
    padding: 0 !important;
}