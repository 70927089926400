.navigation {
    background-color: $color-primary-light;
    // height: 6rem;
    width: 100vw;
    z-index: 999;
    position: fixed;
    top: 0rem;
    left: 0rem;
    margin: 0rem;
    padding: 0rem;

    &__checkbox {
        display: none;
    }

    &__logo-button {
        // height: 100%;
        // width: 7rem;
        position: relative;
        margin: 0.5rem;
        float: left;

        &:hover,
        &:active {
            transform: scale(0.9);
        }
    }

    &__logo {
        margin: 0;
        padding: 0;
        width: 4rem;
    }

    &__button {
        background-color: $color-white;
        height: 0rem;
        width: 0rem;
        position: relative;
        margin: 0rem;
        float: right;
        border-radius: 50%;
        z-index: 2000;
        box-shadow: 0 1rem 3rem rgba($color-black, .1);
        text-align: center;
        cursor: pointer;
        visibility: hidden;
        opacity: 0;

        @include respond(tab-port) {
            height: 4rem;
        width: 4rem;
            margin: 1rem;
            top: 0rem;
            right: 0rem;
            visibility: visible;
            opacity: 1;
        }

        @include respond(phone) {
            height: 4rem;
        width: 4rem;
            margin: 1rem;
            top: 0rem;
            right: 0rem;
            visibility: visible;
            opacity: 1;
        }
    }

    &__background {
        height: 0rem;
        width: 0rem;
        border-radius: 50%;
        position: relative;
        margin-top: 2rem;
        margin-right: -5.9rem;
        float: right;
        background-image: radial-gradient($color-primary-light, $color-primary-dark);
        z-index: 1000;
        transition: transform .8s cubic-bezier(0.86, 0, 0.07, 1);
        visibility: hidden;
        opacity: 0;

        @include respond(tab-port) {
            height: 3.9rem;
            width: 3.9rem;
            top: -1rem;
            right: 0.95rem;
            visibility: visible;
            opacity: 1;
        }

        @include respond(phone) {
            height: 3.9rem;
            width: 3.9rem;
            top: -1rem;
            right: 0.95rem;
            visibility: visible;
            opacity: 1;
        }
    }

    &__nav {
        position: relative;
        float: right;
        padding: 0rem;
        margin: 0rem;
        margin-top: 0rem;
        margin-right: 0rem;        
        height: 100%;
        opacity: 1;
        visibility: visible;
        z-index: 1500;

        @include respond(tab-port) {
            height: 100vh;
            width: 0;
            float: none;
            position: fixed;
            top: 0;
            left: 0;
            opacity: 0;
            visibility: hidden;
            transition: all .8s cubic-bezier(0.68, -0.55, 0.265, 1.55);
        }
    }

    &__list {
        position: relative;
        list-style: none;

        @include respond(tab-port) {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            text-align: center;
            width: 100%;
        }
    }

    &__item {
        margin: 0rem;
        float: left;

        @include respond(tab-port) {
            float: none;
        }
    }

    &__link {
        margin: 0rem;

        &:link,
        &:visited {
            display: inline-block;
            font-size: 2rem;
            font-weight: 300;
            padding: 1rem 1rem;
            color: $color-white;
            text-decoration: none;
            text-transform: uppercase;
            background-size: 230%;
            transition: all .4s;

            span {
                margin-right: 1.5rem;
                display: inline-block;
            }

            @include respond(tab-port) {
                background-image: linear-gradient(120deg, transparent 0%, transparent 50%, $color-white 50%);
                font-size: 3rem;
                padding: 1rem 1rem;
                float: none;
                color: $color-white;
            }
        }
        
        &:hover,
        &:active {
            background-position: 100%;
            transform: translateY(-0.5rem);

            @include respond(tab-port) {
                color: $color-primary;
                transform: translateX(1rem);
            }
        }
    }


    //FUNCTIONALITY
    &__checkbox:checked ~ &__background {
        transform: scale(90);
    }

    &__checkbox:checked ~ &__nav {
        opacity: 1;
        visibility: visible;
        width: 100%;
    }


    //ICON
    &__icon {
        position: relative;
        margin-top: 2rem;

        &,
        &::before,
        &::after {
            width: 2.3rem;
            height: 2px;
            background-color: $color-grey-dark-3;
            display: inline-block;
        }

        &::before,
        &::after {
            content: "";
            position: absolute;
            left: 0;
            transition: all .2s;
        }

        &::before { top: -.7rem; }
        &::after { top: .7rem; }
    }

    &__button:hover &__icon::before {
        top: -0.9rem;
    }

    &__button:hover &__icon::after {
        top: 0.9rem;
    }

    &__checkbox:checked + &__button &__icon {
        background-color: transparent;
    }

    &__checkbox:checked + &__button &__icon::before {
        top: 0;
        transform: rotate(135deg);
    }

    &__checkbox:checked + &__button &__icon::after {
        top: 0;
        transform: rotate(-135deg);
    }
}