.flow-item {
    display: inline-block;
    text-transform: uppercase;
    position: relative;
    // float: left;
    
    &--image {

        img {
            display: block;
            margin: 0 auto;
        }
    }


    &--label {
        text-align: center;
        display: block;
    }
}
