.story {
    width: 100%;
    margin: 0 auto;
    box-shadow: 0 3rem 6rem rgba($color-black, .1);
    background-color: rgba($color-white, .6);
    border-radius: 10px;
    padding: 0rem; // 6rem
    padding-top: 3rem;
    padding-bottom: 3rem;
    // padding-left: 9rem;
    font-size: $default-font-size;

    // @include respond(tab-port) {
    //     padding: 3rem;
    // }

    // @include respond(phone) {
    //     padding: 3rem;
    // }

    &__shape {    
        width: 15rem;
        height: 15rem;
        float: left;
        position: relative;
        overflow: hidden;
        border-radius: 50%;
        margin: 2rem 0rem 2rem 2rem;

        @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
            -webkit-clip-path: circle(50% at 50% 50%);
            clip-path: circle(50% at 50% 50%);
            -webkit-shape-outside: circle(50% at 50% 50%);
            shape-outside: circle(50% at 50% 50%);
            border-radius: none;
        }

        &:last-of-type {
            margin-right: 2rem;

            @include respond(tab-port) {
                margin-right: auto;
            }

            @include respond(phone) {
                margin-right: auto;
            }
        }
    }

    &__img {
        height: 100%;
        transform: scale(1.4);
        backface-visibility: hidden;
        transition: all .5s;
        padding: 0rem;
    }

    &__caption {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, 20%);
        color: $color-white;
        text-transform: uppercase;
        font-size: 1.7rem;
        text-align: center;
        opacity: 0;
        transition: all .5s;
        backface-visibility: hidden;
    }

    &__text {
        padding-left: 2rem;
        padding-right: 2rem;

        @include respond(tab-port) {
            clear: left;
            
        }

        @include respond(phone) {
            clear: left;
        }
    }

    &__gallery {
        @include respond(tab-port) {
            padding: 1rem;
        }
    
        @include respond(phone) {
            padding: 1rem;
        }
    }

    &__social {
        text-align: center;
        margin: 0 auto;

        @include respond(tab-port) {
            clear: left;
            padding-top: 4rem;
        }

        @include respond(phone) {
            clear: left;
            padding-top: 4rem;
            padding-bottom: 0rem;
        }

        & a {
            text-decoration: none;
        }
    }

    &:hover &__caption {
        opacity: 1;
        transform: translate(-50%, -50%);
    }

    &:hover &__img {
        transform: scale(2);
        filter: blur(3px) brightness(80%);
    }

    & ul, & p {
        margin-bottom: 2em
    }

    & ul {
        margin-left: 2rem;
    }
}