/* The Modal (background) */
.modal {
    display: none;
    position: fixed;
    z-index: 3000;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: black;

    & .row > .column {
        padding: 0 8px;
    }

    & .row:after {
        content: "";
        display: table;
        clear: both;
    }

    /* Create four equal columns that floats next to eachother */
    & .column {
        float: left;
        width: 25%;
    }
}

/* Modal Content */
.modal-content {
    position: relative;
    background-color: black;
    margin: auto;
    padding: 0;
    width: 90%;
    max-width: 1200px;
    max-height: 80vh !important;
}

/* The Close Button */
.close {
    color: white;
    position: absolute;
    top: 10px;
    right: 25px;
    font-size: 35px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: #999;
    text-decoration: none;
    cursor: pointer;
}

/* Hide the slides by default */
.slides {
    height: 80vh;
    display: none;

    & img {
        max-height: 80vh !important;
        -o-object-fit: contain;
        object-fit: contain;
    }

    & figcaption {
        font-size: 1.5rem;
        color: $color-grey-light-1;
    }
}

/* Next & previous buttons */
.prev {
    left: -30pt;
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 16px;
    margin-top: -50px;
    color: white;
    font-weight: bold;
    font-size: 20px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
    -webkit-user-select: none;

    @include respond(tab-port) {
        left: -23pt;
    }

    @include respond(phone) {
        left: -23pt;
    }
}

/* Position the "next button" to the right */
.next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 16px;
    margin-top: -50px;
    color: white;
    font-weight: bold;
    font-size: 20px;
    transition: 0.6s ease;
    user-select: none;
    -webkit-user-select: none;
    right: -30pt;
    border-radius: 3px 0 0 3px;

    @include respond(tab-port) {
        right: -23pt;
    }

    @include respond(phone) {
        right: -23pt;
    }
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover,
.next:hover {
    background-color: rgba(0, 0, 0, 0.8);
}

/* Caption text */
.text {
    color: #f2f2f2;
    font-size: 15px;
    padding: 8px 12px;
    position: absolute;
    bottom: 8px;
    width: 100%;
    text-align: center;
}

/* Number text (1/3 etc) */
.numbertext {
    color: #f2f2f2;
    font-size: 12px;
    padding: 8px 12px;
    position: absolute;
    top: -30pt;
}

/* The dots/bullets/indicators */
.dot {
    cursor: pointer;
    height: 15px;
    width: 15px;
    margin: 0 2px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
}

.active,
.dot:hover {
    background-color: #717171;
}

/* Caption text */
.caption-container {
    text-align: center;
    background-color: black;
    padding: 2px 16px;
    color: white;
}

img.demo {
    opacity: 0.6;
}

.active,
.demo:hover {
    opacity: 1;
}

img.hover-shadow {
    transition: 0.3s;
}

.hover-shadow:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

/* Fading animation */
.fade {
    -webkit-animation-name: fade;
    -webkit-animation-duration: 1.5s;
    animation-name: fade;
    animation-duration: 1.5s;
}

@-webkit-keyframes fade {
    from {
        opacity: 0.4;
    }
    to {
        opacity: 1;
    }
}

@keyframes fade {
    from {
        opacity: 0.4;
    }
    to {
        opacity: 1;
    }
}
