.album {
    margin-top: 1rem;
    margin-bottom: 2rem;

    & h1 {
        text-align: center;
    }

    & .polaroid {
        margin: auto;
    }

    & .polaroid:hover {
        transform: translateY(-6px);
    }

    & .gallery {
        max-width: 100%;
        margin: 0 auto;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(296px, 0.8fr));
        grid-gap: 4rem 1rem;

        @include respond(tab-port) {
            grid-template-columns: 1fr;
        }
    
        @include respond(phone) {
            grid-template-columns: 1fr;
        }

        & .btn-text {
            &:hover, &:link, &:visited, &:active, &:focus {
                text-decoration: none;
                border-bottom: none;
            }    
        }
    }

    & .galleries {
        max-width: 100%;
        margin: 0 auto;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(296px, 0.8fr));
        grid-gap: 4rem 3rem;

        @include respond(tab-port) {
            grid-template-columns: 1fr;
        }
    
        @include respond(phone) {
            grid-template-columns: 1fr;
        }

        & .btn-text {
            &:hover, &:link, &:visited, &:active, &:focus {
                text-decoration: none;
                border-bottom: none;
            }    
        }
    }
}
