:root {
    --polaroid-width: 296px;

    // @include respond(tab-port) {
    //     clear: left;
    //     --polaroid-width: min(50rem, calc(100vw - 8rem));
    // }

    @include respond(phone) {
        // clear: left;
        --polaroid-width: calc(100vw - max(100vw - 40rem, 10rem));
    }

    --polaroid-height: var(--polaroid-width);//calc(var(--polaroid-width) * 1.15);
}

.polaroid {
    position: relative;
    margin: 0 auto;
    padding: 0 auto;
    width: var(--polaroid-width);
    // height: auto; //var(--polaroid-height);
    background: #fff;
    // box-shadow: 1px 4px 12px rgba(0, 0, 0, 0.5);
    transition: transform 0.1s linear;
    display: block;

    & img {
        --size: var(--polaroid-width); //calc(var(--polaroid-width) * 0.95);
        box-shadow: 1px 4px 12px rgba(0, 0, 0, 0.3);
        display: block;
        margin: auto;
        // padding-top: calc(var(--polaroid-width) * 0.06);
        max-width: var(--size);
        // height: var(--size);
        max-height: var(--size);
        object-fit: contain;
        border: 4pt solid white;
    }
}

.polaroid-stack {
    margin: 0 auto;
    overflow: visible;
    position: relative;

    & figcaption {
        font-size: 1.3rem;
        text-align: center;
        margin-top: 1rem;
        text-decoration: none;
        min-height: 2rem;
        padding-top: 0.5rem;
        border-top: 1pt solid $color-grey-light-3;
    }

    & div {
        width: var(--polaroid-width);
        height: var(--polaroid-height);
        margin: auto;

        & .polaroid {
            position: absolute;
            top: 0;
        }
        // & .polaroid:first-child {
        //     transform: rotate(4deg);
        // }
        // & .polaroid:nth-child(2) {
        //     transform: rotate(-3deg);
        // }
        // & .polaroid:nth-child(3) {
        //     transform: rotate(3deg);
        // }
        // & .polaroid:nth-child(4) {
        //     transform: rotate(-5deg);
        // }
    }

    // &:hover div {
    //     & .polaroid:first-child {
    //         transform: rotate(6deg);
    //     }
    //     & .polaroid:nth-child(2) {
    //         transform: rotate(-5deg);
    //     }
    //     & .polaroid:nth-child(3) {
    //         transform: rotate(5deg);
    //     }
    //     & .polaroid:nth-child(4) {
    //         transform: rotate(-7deg);
    //     }
    // }
}

.lightbox {
    & img {
        // position: relative;
        border: 4pt solid white;
        // text-align: center;
        max-width: 100%;
        margin: 0 auto;
        display: block;
    }
}