.photo {
    --margin-top: var(--sizing-scale-4);
    width: 95vw;
    height: calc(100vh - var(--margin-top));
    margin: auto;
    margin-top: var(--margin-top);
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-template-rows: 90% 10%;
}

.photo .back-link a {
    display: flex;
    align-items: center;
}

.photo .back-link a:hover {
    text-decoration: underline;
}

.photo .back-link .icon {
    height: var(--sizing-scale-4);
    width: var(--sizing-scale-4);
    stroke: #000;
    margin-right: var(--sizing-scale-1);
}

.photo__image {
    grid-column: 2;
    grid-row: 1;
}

.photo__image>img {
    height: 100%;
    width: 100%;
    display: block;
    -o-object-fit: contain;
    object-fit: contain;
}

.photo__navigators {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-column: 2;
    grid-row: 2;
}

.photo__navigators a.clicked .icon,.photo__navigators a:focus .icon {
    transform: translate(2px,2px);
}

.photo__navigators .icon {
    display: block;
    margin: var(--sizing-scale-4) var(--sizing-scale-8);
    width: var(--sizing-scale-7);
    height: var(--sizing-scale-7);
    stroke: #000;
}

@media (max-width:1100px) {
    .photo__navigators .icon {
        width: var(--sizing-scale-6);
        height: var(--sizing-scale-6);
    }    
}

@media (max-width:600px) {
    .photo__navigators .icon {
        width: var(--sizing-scale-5);;
        height: var(--sizing-scale-5)
    }
}

.photo__data-container {
    width: 35vw;
    margin: auto;
    font-size: var(--text-scale-5);
    font-family: monospace;
    grid-column: 1;
    grid-row: 1;
    align-self: center;
}

.photo__data-container p {
    margin: 0 var(--sizing-scale-5);
}

.photo__data {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.photo__data>div {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    margin: var(--sizing-scale-4) var(--sizing-scale-5);
}

.photo__data>div svg {
    flex: 0 0 auto;
}

@media (max-width:600px) {
    .photo__data>div {
        justify-content: flex-start;
        margin: var(--sizing-scale-4) var(--sizing-scale-3);
    }    
}

.photo__data .icon {
    width: var(--sizing-scale-6);
    height: var(--sizing-scale-6);
    margin-right: var(--sizing-scale-4);
}

@media (max-width:1100px) {
    .photo {
        --margin-top: var(--sizing-scale-8);
        height: auto;
        grid-template-columns: 1fr;
        grid-template-rows: 80vh var(--sizing-scale-9) 1fr;
    }

    .photo__image {
        grid-column: 1;
        grid-row: 1;
    }
    
    .photo__navigators {
        grid-column: 1;
        grid-row: 2;
    }

    .photo__data-container {
        grid-column: 1;
        grid-row: 3;
        width: 90%;
        margin: auto;
    }

    .photo__data-container p {
        text-align: center;
        margin: auto;
    }

    .photo__data {
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
    }
    
    .photo__data, .photo__data>div {
        justify-content: center;
    }
}

.photo .hidden {
    visibility: hidden;
}
 
@media (max-width:900px) {
    .photo__data {
        display: grid;
        grid-template-columns: repeat(auto-fit,minmax(220px,1fr));
    }
    
    .photo__data>div {
        justify-content: unset;
    }    
}

@media (max-width:600px) {
    .photo {
        grid-template-rows: 70vh var(--sizing-scale-9) 1fr;
    }
    .photo__data-container {
        width: 60%;
    }    
}