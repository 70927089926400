.header__gallery {
    // background-image: linear-gradient(
    //     to right bottom,
    //     rgba($color-primary-light, 0.4),
    //     rgba($color-primary-dark, 0.6)),
    // url(../assets/header.jpg);
    position: relative;
    margin: 20rem;

    @include respond(tab-port) {
        margin: 20rem 6rem 20rem 6rem ;
    }

}